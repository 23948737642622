<template>
  <b-card
  >
    <LabelQr v-if="imageQr" :image-qr="imageQr" ticket_id="label-image"/>
    <b-modal
        hide-footer
        id="modal-prevent-closing-qr-view"
        ref="my-modal"
        :title="`Qr`"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >
      <b-overlay
          :show="show2"
          no-wrap
      />
      <b-row  v-if="infoLabel">
        <b-col cols="12" lg="6">
          <div align="center">
            <img v-if="imageQr" :src="imageQr" alt="">
          </div>
          <div align="center">
            <b-button
                @click="printTicket"
                style="width: 25%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Imprimir
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div align="center" style="margin-top: 20px; font-size: 17px">
            Id Producto: <strong>{{infoLabel.product_id}}</strong> <br><br>
            Lote: <strong>{{infoLabel.lots}}</strong><br><br>
            Fecha de caducidad: <strong>{{infoLabel.date_expiry}}</strong><br><br>
            Código de barras : <strong>{{infoLabel.barcode ? infoLabel.barcode : ''}}</strong><br><br>
            Consecutivo: <strong>{{infoLabel.consecutive ? infoLabel.consecutive : ''}}</strong><br><br>
          </div>

        </b-col>
      </b-row>

    </b-modal>
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-row class="mb-3">
      <b-col
          lg="12"
      >
        <h2>Agregue una nueva etiqueta</h2>
      </b-col>
      <b-col
          lg="12"
      >
        <b-form @submit.prevent="editLabel(send)">
          <b-row v-if="infoLabel">
            <b-col cols="12" >
              <b-form-group
                  label="Consecutivo"
              >
                <b-form-input
                    disabled=""
                    v-model="consecutive"
                    placeholder="Consecutivo"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Producto"
              >
                <b-form-input
                    disabled=""
                    v-model="infoLabel.ProductTemplate.name"
                    placeholder="Producto"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                  label="Marca"
              >
                <b-form-input
                    disabled=""
                    v-model="infoLabel.brand"
                    placeholder="Marca"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group
                  label="Precio venta"
              >
                <b-form-input
                    disabled=""
                    v-model="infoLabel.ProductTemplate.list_price"
                    placeholder="Precio venta"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group
                  label="Unidad de Medida"
              >
                <b-form-input
                    disabled=""
                    v-model="infoLabel.ProductTemplate.UomUom.name"
                    placeholder="Unidad de Medida"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                  label="Lote"
              >
                <b-form-input
                    v-model="send.lots"
                    placeholder="Lote"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                  label="Fecha de caducidad"
              >
                <flat-pickr
                    v-model="send.date_expiry"
                    class="form-control"
                    :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
                />
                <div v-if="send.date_expiry"
                     style="position: absolute; margin-top: -28px; right: 30px; z-index: 100; cursor: pointer"
                     @click="clearDate">
                  <strong>X</strong>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="6" class="mt-1">
              <b-button
                  v-if="consecutive"
                  style="width: 25%"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  @click="generateQr"
                  class="mr-1"
              >
                Ver QR
              </b-button>
            </b-col>
            <b-col cols="6" class="mt-1">
              <div align="right">
                <b-button
                    style="width: 25%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                >
                  Guardar
                </b-button>
              </div>


            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BForm, BOverlay,
  BFormRadio

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import Ripple from "vue-ripple-directive";
import flatPickr from 'vue-flatpickr-component'
import {mapGetters, mapActions} from "vuex";
import {VueAutosuggest} from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import LabelQr from "@/components/LabelQr/LabelQr";
import jsPDF from "jspdf";

export default {
  name: "EditLabel",
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar, flatPickr, vSelect, VueAutosuggest, BOverlay, BFormRadio,LabelQr
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      optionsProducts: [],
      itemProductsAll:[],
      selectProduct: null,
      show: false,
      show2: false,
      imageQr: null,
      checkBarcode: false,
      consecutive: null,
      infoLabel: null,
      send: {
        lots: null,
        product_id: null,
      }

    }
  },

  async created() {
    this.id = this.$route.params.id;
    await this.findOneLabel(this.id);
    await this.findProducts();
  },
  methods: {
    ...mapActions('pharmacy', ['updateLabel', 'getConsecutive', 'generateQrId', 'getOne']),
    ...mapActions('treatment', ['findProduct']),
    async generateQr(){
      this.show = true;
      const response = await this.generateQrId(this.id);
      if (response.code){
        this.show = false
        this.imageQr =  response.code;
        await this.$bvModal.show('modal-prevent-closing-qr-view');
      }else{
        this.show = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: 'error',
            variant: 'warning',
          },
        });
      }

    },
    async clearDate() {
      this.send.date_expiry = null;
    },
    async findOneLabel(id) {
      this.show = true

      const response = await this.getOne(id);
      this.infoLabel =  response.data;
      if (response.data) {
        this.show = false
        this.consecutive = response.data.consecutive
        this.send = {
          lots: response.data.lots,
          product_id: response.data.product_id,
          brand: response.data.brand,
          date_expiry: response.data.date_expiry,
        }
      } else {
        this.show = false
      }
    },
    async editLabel(data) {
      const responseVerify = await this.verifyData();
      if (!responseVerify){
        return
      }
      this.show = true
      this.$swal({
        title: 'Editar etiqueta',
        text: `Deseas editar el registro? Un registro editado no puede ser recuperado`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const response =  await this.updateLabel({id:this.id, data:this.send});
          if (response.msg) {
            this.$swal({
              icon: 'success',
              title: 'Editado!',
              text: response.msg,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            await this.findOneLabel(this.id);
            await this.generateQr()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error,
                icon: 'error',
                variant: 'warning',
              },
            });
            this.show = false
          }


        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })
    },

    async findProducts() {
      const response = await this.findProduct();
      const findData = response.find((a) => a.id === this.send.product_id);
      const dataSed = findData.default_code ? '(' + findData.default_code + ')' : ''
      this.selectProduct = {
        id: findData.id,
        value: findData.name + ' ' + dataSed
      }

      for (const a of response) {
        const de = a.default_code ? '(' + a.default_code + ')' : ''
        this.optionsProducts.push({
          id: a.id,
          value: a.name + ' ' + de
        })
        this.itemProductsAll.push({
          id:a.id,
          name:a.name,
          brand: a.default_code
        })
      }


    },
    async searchByProduct() {
      this.send.product_id = this.selectProduct ? this.selectProduct.id : null;
      const searchId = this.itemProductsAll.find((a) => a.id === this.send.product_id);
      if (searchId && searchId.brand){
        this.send.brand = searchId.brand;
      }else{
      }
    },
    async resetModal() {

    },
    async verifyData(){
      if (!this.send.product_id){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No ha seleccionado ningun producto',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.send.lots){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo lote requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.send.brand){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Marca requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.send.date_expiry){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Fecha de caducidad requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      return true
    },
    async printTicket() {
      this.show2 = true
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4"
      }
      var doc = new jsPDF(options);
      doc.setFontSize(10);
      const img = new Image()
      img.src = this.imageQr
      doc.addImage(img, 'png', 8, 1, 5, 5)
      doc.output('dataurlnewwindow');
      this.show2 = false

    },
  }
}
</script>

<style scoped>

</style>