<template>
  <div :id="ticket_id" class="ticket" style="width: 230px; height: auto; overflow: auto; padding-left: 10px">
    <div style="font-family: sans-serif">
      <div style="text-align: center">
        <img v-if="imageQr" width="100" :src="imageQr" alt="">
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "LabelQr",
  props: {
    ticket_id: String,
    imageQr: String,
  },
  created() {
  },

  watch: {
    rows(value) {
    }
  }

}
</script>


<style scoped>
.ticket {
  width: 230px;
  height: auto;
  overflow: auto;
  display: none;
}
</style>
